export const cufrontLogos = {
    topbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logomark+.svg",
        ndhealthwellclinic:
            "https://testapi.bharathealpoint.com/assets/fd243092-459a-49ae-806a-319743cbb873",
    },
    navbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
        ndhealthwellclinic:
            "https://testapi.bharathealpoint.com/assets/fd243092-459a-49ae-806a-319743cbb873",
    },
    ndhealthwellclinic:
        "https://cufront-assets.s3.amazonaws.com/logos/healthwellLogo.png",
    cufront: "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
};
