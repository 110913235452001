import { cufrontIcons } from "../assets/icons/icons";
import AllRecords from "../layouts/AllRecords";
import Bills from "../layouts/Bills";
import Certificates from "../layouts/Certificates";
import LabTest from "../layouts/LabTest";
import Prescriptions from "../layouts/Prescriptions";
import { RiChat3Line, RiHome2Fill, RiHome2Line } from "react-icons/ri";
import { FaRegFile, FaUserEdit } from "react-icons/fa";
import {
    BsCalendar2Minus,
    BsCalendar2MinusFill,
    BsFillPeopleFill,
} from "react-icons/bs";
import { FaFile } from "react-icons/fa";
import { RiChat3Fill } from "react-icons/ri";
import { BsFillPersonFill, BsPerson } from "react-icons/bs";
import { IoMdNotifications } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoLinkOutline } from "react-icons/io5";
import { FiLock } from "react-icons/fi";
import { BiHomeAlt2 } from "react-icons/bi";
import { BiSolidHomeAlt2 } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Visits from "../pages/Visits";

export const chatActive = cufrontIcons.chatActive;
export const chatInactive = cufrontIcons.chatInactive;
export const notificationActive = cufrontIcons.notificationActive;
export const notificationInactive = cufrontIcons.notificationInactive;

export const DOMAIN = "gmail.com";
export const IMAGE_BASE_URL = `https://cufrontdata.s3.amazonaws.com`;

export const PatientsSidebarOptions = () => {
    const { t } = useTranslation();

    const patientsSidebarOptions = [
        {
            id: "1",
            name: t("home"),
            route: "/p",

            activeIcon: <RiHome2Fill size={25} />,
            inactiveIcon: <RiHome2Line color="#999999" size={25} />,
        },
        {
            id: "2",
            name: t("appointment"),
            route: "/p/appointment?slot_status=upcoming",
            activeIcon: <BsCalendar2MinusFill size={25} />,
            inactiveIcon: <BsCalendar2Minus color="#999999" size={25} />,
        },
        {
            id: "3",
            name: t("records"),
            route: "/p/record?options=visits",
            activeIcon: <FaFile size={25} />,
            inactiveIcon: <FaRegFile color="#999999" size={25} />,
        },
        {
            id: 4,
            name: t("chat"),
            route: "/p/chat",
            activeIcon: <RiChat3Fill size={25} />,
            inactiveIcon: <RiChat3Line color="#999999" size={25} />,
        },
        {
            id: "5",
            name: t("profile"),
            route: "/p/profile",
            activeIcon: <BsFillPersonFill size={25} />,
            inactiveIcon: <BsPerson color="#999999" size={25} />,
        },
    ];
    return patientsSidebarOptions;
};

export const patientTopbarOptions = [
    {
        id: 3,
        name: "Call",
        route: "/p/call",
        activeIcon: cufrontIcons.callActive,
        inactiveIcon: cufrontIcons.callInactive,
    },
];

export const useNavbarOptions = () => {
    const { t } = useTranslation();
    const navbarOptions = [
        {
            id: 1,
            name: t("home"),
            route: "/p",

            activeIcon: <BiSolidHomeAlt2 className="text-2xl" />,
            inactiveIcon: <BiHomeAlt2 color="#999999" className="text-2xl" />,
        },
        {
            id: 2,
            name: t("appointment"),
            route: "/p/appointment?slot_status=upcoming",
            activeIcon: <BsCalendar2MinusFill className="text-xl" />,
            inactiveIcon: (
                <BsCalendar2Minus color="#999999" className="text-xl" />
            ),
        },
        {
            id: 3,
            name: t("medicalRecord"),
            route: "/p/record?options=visits",
            activeIcon: <FaFile size={20} className="text-xl" />,
            inactiveIcon: <FaRegFile color="#999999" className="text-xl" />,
        },
        {
            id: 4,
            name: t("chat"),
            route: "/p/chat",
            activeIcon: <RiChat3Fill className="text-2xl" />,
            inactiveIcon: <RiChat3Line color="#999999" className="text-2xl" />,
        },

        {
            id: 5,
            name: t("profile"),
            route: "/p/profile",
            activeIcon: <BsFillPersonFill className="text-2xl" />,
            inactiveIcon: <BsPerson color="#999999" className="text-2xl" />,
        },
    ];
    return navbarOptions;
};

export const ApiEndPoint = {
    CHANGE_PASSWORD: "change/",
    USERS: `users`,
    QM_EXTERNAL_CANCELLED: "qm_external_cancelled",
    QM_NT_EXTERNAL: "qm_nt_external",
    QM_NT_NOTIFICATION: "qm_nt_notification",
    QM_PM_LIST: "qm_pm_list",
    QM_SC_AVAILABILITY: "qm_sc_availability",
    QM_SC_BLOCKED_SLOTS: "qm_sc_blocked_slots",
    QM_SC_FIELDS: "qm_sc_fields",
    QM_SC_NOTICE_BOARD: "qm_sc_notice_board",
    QM_SC_PATIENT_RECORD: "qm_sc_patient_record",
    QM_SC_PERSONA: "qm_sc_persona",
    QM_SC_SLOT_TYPE: "qm_sc_slot_type",
    QM_SC_SLOTS: "bookings/",
    QM_SC_USERS_PERSONAL_DETAILS: "qm_sc_users_personal_details",
    QM_SC_VISIBILITY: "qm_sc_visibility",
    QM_SC_WALKIN_SLOTS: "qm_sc_walkin_slots",
    QP_CM_DOSAGE: "qp_cm_dosage",
    QP_CM_FREQUENCY: "qp_cm_frequency",
    QP_CM_CONSULTATION_DATA: "consultation/",
    QP_CM_GENERIC_NAME: "qp_cm_generic_name",
    QP_CM_LANGUAGE: "qp_cm_languages",
    QP_CM_MEDICINE_DATA: "qp_cm_medicine_data",
    QP_CM_RX_GROUP: "qp_cm_rx_group",
    QP_CM_SALUTATION: "qp_cm_salutation",
    QP_CM_TAG_TYPES: "qp_cm_tag_types",
    QP_CM_TAGS: "qp_cm_tags",
    QP_CM_TEMPLATES: "qp_cm_templates",
    QP_CM_TIMING: "qp_cm_timing",
    QP_CM_UNITS: "qp_cm_units",
    QP_CM_WHEN: "qp_cm_when",
    BHP_REGISTERED_CLINIC: "bhp_registered_clinic",
    QM_SC_ONLINE_APPOINTMENT_DETAILS: "qm_sc_online_appointment_details",
    QP_CM_ALLERGIES: "qp_cm_allergies",
    QP_CM_DOCTOR_SETTING: "qp_cm_doctor_setting",
    QP_CM_MEDICINE_CATEGORY: "qp_cm_medicine_category",
    QP_CM_MEDICINE_TYPE: "qp_cm_medicine_type",
    QP_CM_NOTES: "qp_cm_notes",
    QP_CM_NOTE_VALUES: "qp_cm_note_values",
    QP_CM_PATIENT_HISTORY: "qp_cm_patient_history",
    QP_CM_TAG_GROUPS: "qp_cm_tag_groups",
    QP_CM_VITAL_TYPE: "qp_cm_vital_type",
    QP_CM_VITALS: "qp_cm_vitals",
    BOOKINGS: "bookings/",
    MEDICINE_TAG_DATA: "master_data/medicine_data/",
};

export const useSlotStatus = () => {
    const { t } = useTranslation();
    return [
        {
            name: "upcoming",
            displayName: t("upcoming"),
        },
        {
            name: "completed",
            displayName: t("completed"),
        },
    ];
    // return ["Upcomingg", t("completed")];
};

export const PRIMARY_CLR = "#C7EAC9";

export const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];

export const Menu = () => {
    const { t } = useTranslation();
    const menuItems = [
        {
            label: t("edit_profile"),
            icon: <FaUserEdit size={35} color="#999999" />,
            link: "edit-profile",
            includeId: true,
            dimension: { w: "35.38px", h: "32px" },
        },
        {
            label: t("manage_family_profile"),
            icon: <BsFillPeopleFill size={35} color="#999999" />,
            link: "manage-profile",
            dimension: { w: "35.71px", h: "32px" },
        },
        {
            label: t("manage_password"),
            icon: <FiLock size={35} color="#999999" />,
            link: "manage-password",
            dimension: { w: "35.71px", h: "32px" },
        },
        {
            label: t("notification_settings"),
            icon: <IoMdNotifications size={35} color="#999999" />,
            link: "notification-settings",
            dimension: { w: "25px", h: "31.25px" },
        },
        {
            label: t("language"),
            icon: <MdLanguage size={35} color="#999999" />,
            link: "language",
            dimension: { w: "32px", h: "32px" },
        },
        {
            label: t("about_us"),
            icon: <IoMdInformationCircleOutline size={35} color="#999999" />,
            link: "about-us",
            dimension: { w: "32px", h: "32px" },
        },

        {
            label: t("link_user"),
            icon: <IoLinkOutline size={35} color="#999999" />,
            link: "link-user",
            dimension: { w: "28px", h: "18px" },
        },
    ];
    return menuItems;
};

export const EditProfileInputConfig = () => {
    const { t } = useTranslation();
    const editProfileInputConfigs = [
        { label: t("name"), key: "full_name", type: "text" },
        { label: t("phoneNumber"), key: "phone", type: "text" },
        { label: t("email"), key: "email", type: "email" },
        { label: t("dob"), key: "dob", type: "date" },
        {
            label: t("gender"),
            key: "gender",
            type: "select",
            options: [
                { key: "male", value: "Male", text: t("male") },
                { key: "female", value: "Female", text: t("female") },
                {
                    key: "other",
                    value: "Other",
                    text: t("other"),
                },
            ],
        },
        {
            label: t("bloodGroup"),
            key: "blood_group",
            type: "select",
            options: [
                { key: "A+", value: "A+", text: "A+" },
                { key: "A-", value: "A-", text: "A-" },
                { key: "B+", value: "B+", text: "B+" },
                { key: "B-", value: "B-", text: "B-" },
                { key: "AB+", value: "AB+", text: "AB+" },
                { key: "AB-", value: "AB-", text: "AB-" },
                { key: "O+", value: "O+", text: "O+" },
                { key: "O-", value: "O-", text: "O-" },
            ],
        },
        { label: t("street_locality"), key: "address", type: "text" },
        { label: t("city"), key: "city", type: "text" },
        { label: t("pincode"), key: "zip_code", type: "text" },
        {
            label: t("state"),
            key: "state",
            type: "select",
            options: [
                {
                    key: "andhra_pradesh",
                    value: "Andhra Pradesh",
                    text: t("andhraPradesh"),
                },
                {
                    key: "arunachal_pradesh",
                    value: "Arunachal Pradesh",
                    text: t("arunachalPradesh"),
                },
                {
                    key: "assam",
                    value: "Assam",
                    text: t("assam"),
                },
                {
                    key: "bihar",
                    value: "Bihar",
                    text: t("bihar"),
                },
                {
                    key: "chhattisgarh",
                    value: "Chhattisgarh",
                    text: t("chhattisgarh"),
                },
                {
                    key: "goa",
                    value: "Goa",
                    text: t("goa"),
                },
                {
                    key: "gujarat",
                    value: "Gujarat",
                    text: t("gujarat"),
                },
                {
                    key: "haryana",
                    value: "Haryana",
                    text: t("haryana"),
                },
                {
                    key: "himachal_pradesh",
                    value: "Himachal Pradesh",
                    text: t("himachalPradesh"),
                },
                {
                    key: "jharkhand",
                    value: "Jharkhand",
                    text: t("jharkhand"),
                },
                {
                    key: "karnataka",
                    value: "Karnataka",
                    text: t("karnataka"),
                },
                {
                    key: "kerala",
                    value: "Kerala",
                    text: t("kerala"),
                },
                {
                    key: "madhya_pradesh",
                    value: "Madhya Pradesh",
                    text: t("madhyaPradesh"),
                },
                {
                    key: "maharashtra",
                    value: "Maharashtra",
                    text: t("maharashtra"),
                },
                {
                    key: "manipur",
                    value: "Manipur",
                    text: t("manipur"),
                },
                {
                    key: "meghalaya",
                    value: "Meghalaya",
                    text: t("meghalaya"),
                },
                {
                    key: "mizoram",
                    value: "Mizoram",
                    text: t("mizoram"),
                },
                {
                    key: "nagaland",
                    value: "Nagaland",
                    text: t("nagaland"),
                },
                {
                    key: "odisha",
                    value: "Odisha",
                    text: t("odisha"),
                },
                {
                    key: "punjab",
                    value: "Punjab",
                    text: t("punjab"),
                },
                {
                    key: "rajasthan",
                    value: "Rajasthan",
                    text: t("rajasthan"),
                },
                {
                    key: "sikkim",
                    value: "Sikkim",
                    text: t("sikkim"),
                },
                {
                    key: "tamil_nadu",
                    value: "Tamil Nadu",
                    text: t("tamilNadu"),
                },
                {
                    key: "telangana",
                    value: "Telangana",
                    text: t("telangana"),
                },
                {
                    key: "tripura",
                    value: "Tripura",
                    text: t("tripura"),
                },
                {
                    key: "uttar_pradesh",
                    value: "Uttar Pradesh",
                    text: t("uttarPradesh"),
                },
                {
                    key: "uttarakhand",
                    value: "Uttarakhand",
                    text: t("uttarakhand"),
                },
                {
                    key: "west_bengal",
                    value: "West Bengal",
                    text: t("westBengal"),
                },
            ],
        },
        {
            label: t("country"),
            key: "country",
            type: "select",
            options: [{ key: "india", value: "India", text: t("india") }],
        },
        { label: t("language"), key: "language", type: "text" },
        {
            label: t("alternateContactNumber"),
            key: "alternate_phone",
            type: "text",
        },
    ];
    return editProfileInputConfigs;
};

export const Medical = () => {
    const { t } = useTranslation();

    const medicalTabs = [
        { name: "Visits", label: t("visits"), component: <Visits /> },
        {
            name: "Prescriptions",
            label: t("prescriptions"),
            component: <Prescriptions />,
        },
        { name: "Bills", label: t("bills"), component: <Bills /> },
        {
            name: "Certificates",
            label: t("certificates"),
            component: <Certificates />,
        },
        { name: "Lab_Reports", label: t("labTests"), component: <LabTest /> },
    ];
    return medicalTabs;
};
export const medicalTabs = [
    { name: "Visits", label: "Visits", component: <Visits /> },
    {
        name: "Prescriptions",
        label: "Prescriptions",
        component: <Prescriptions />,
    },
    { name: "Bills", label: "Bills", component: <Bills /> },
    {
        name: "Certificates",
        label: "Certificates",
        component: <Certificates />,
    },
    { name: "Lab_Reports", label: "Lab Tests", component: <LabTest /> },
];

export const daysOfWeek = [
    {
        value: "sunday",
        label: "S",
    },
    {
        value: "monday",
        label: "M",
    },
    {
        value: "tuesday",
        label: "T",
    },
    {
        value: "wednesday",
        label: "W",
    },
    {
        value: "thursday",
        label: "T",
    },
    {
        value: "friday",
        label: "F",
    },
    {
        value: "saturday",
        label: "S",
    },
];

export const record_type = [
    {
        id: 1,
        type: "Prescriptions",
    },

    {
        id: 2,
        type: "Bills",
    },

    {
        id: 3,
        type: "Certificates",
    },

    {
        id: 4,
        type: "Lab_Reports",
    },
];

export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const filterByTagType = (data, tagType) => {
    return data?.filter((item) => item?.tag_type?.includes(tagType));
};

export const pageSizes = {
    A4: { width: 21, height: 29.7 },
    A5: { width: 14.8, height: 21 },
    A3: { width: 29.7, height: 42 },
    // Add more page sizes as needed
};
