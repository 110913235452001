import moment from "moment";

export const capitalizeFirstWord = (input) => {
    return input
        .toLowerCase()
        .replace(/^(.)|\s(.)/g, (match) => match.toUpperCase());
};

export const generateUUID = () => {
    const min = 100000;
    const max = 999999;
    const randomID = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomID;
};

export const getTimeInSeconds = (date) => {
    const parsedDate = moment(new Date(date + "Z"));
    const seconds =
        parsedDate.hours() * 3600 +
        parsedDate.minutes() * 60 +
        parsedDate.seconds();
    return seconds;
};

export const getFullDayName = (abbreviatedDay) => {
    const dayMappings = {
        Sun: "sunday",
        Mon: "monday",
        Tue: "tuesday",
        Wed: "wednesday",
        Thu: "thursday",
        Fri: "friday",
        Sat: "saturday",
    };

    return dayMappings[abbreviatedDay];
};

export const generateUniqueReportName = (prefix) => {
    // Get the ISO string and remove milliseconds and 'Z'
    const isoString = new Date().toISOString();
    const timestamp = isoString
        .substring(0, isoString.indexOf("."))
        .replace(/[-:T]/g, "");
    return `${prefix}-${timestamp}`;
};

// export const truncateText = (text, wordLimit) => {
//     if (!text) return ""; // Return empty string if text is null or undefined
//     const words = text.split(" ");
//     if (words.length > wordLimit) {
//         return words.slice(0, wordLimit).join(" ") + "...";
//     }
//     return text;
// };

export function modifyString(str, toUpperCase = true) {
    // Remove all spaces
    let result = str.replace(/\s+/g, "");

    // Convert to upper or lower case based on the parameter
    if (toUpperCase) {
        result = result.toUpperCase();
    } else {
        result = result.toLowerCase();
    }

    return result;
}

export function calculateAge(dob) {
    var currentDate = new Date();
    var birthDate = new Date(dob);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    var monthDiff = currentDate.getMonth() - birthDate.getMonth();
    if (
        monthDiff < 0 ||
        (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    return age;
}

export const deepCopy = (obj, visited = new WeakMap()) => {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    if (visited.has(obj)) {
        return visited.get(obj);
    }

    const newObj = Array.isArray(obj) ? [] : {};
    visited.set(obj, newObj);

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key] = deepCopy(obj[key], visited);
        }
    }
    return newObj;
};

export function dateToISO8601(date) {
    const utcDate = moment.utc(date).startOf("day");
    const isoDate = utcDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
    return isoDate;
}

export function dateConvertToPayloadBookingFormat(date) {
    const inputDateString = date;

    const utcDate = moment.utc(inputDateString);

    const formattedDateString = utcDate.format("YYYY-MM-DDTHH:mm:ss[Z]");

    return formattedDateString;
}

export const GetCSRFTokenFromCookies = () => {
    return null;
};

export const getParentUser = (data) => {
    const filtered = data?.filter((ele) => ele?.is_parent === true);
    return filtered[0];
};

export const calculateAgeString = (birthdateString) => {
    const birthdate = new Date(birthdateString);
    const currentDate = new Date();

    const differenceInMilliseconds = currentDate - birthdate;
    const age = Math.floor(
        differenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)
    );

    return age;
};

export function isFullNameExist(fullName, dataArray) {
    // Remove spaces from the given full name and convert it to lowercase
    const fullNameWithoutSpaces = fullName.replace(/\s/g, "").toLowerCase();

    // Check if any object in the array has a full name without spaces that matches the given full name without spaces
    return dataArray.some(
        (obj) =>
            obj.full_name.replace(/\s/g, "").toLowerCase() ===
            fullNameWithoutSpaces
    );
}

export const formatSecondsToHHMM = (seconds) => {
    if (seconds === "") {
        return "";
    }

    // Convert UTC seconds to hours and minutes
    const hours = Math.floor((seconds + 19800) / 3600); // Add 19800 seconds to convert from IST to UTC
    const minutes = Math.floor(((seconds + 19800) % 3600) / 60);

    // Format hours and minutes with leading zeros
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
};

export const GetInSeconds = (time) => {
    let time1 = time.split(":");
    // converting IST to utc seconds
    return Number(time1[0]) * 3600 + Number(time1[1]) * 60 - 19800;
};

export const validateFullName = (fullName = "") => {
    let trimmedValue = fullName.trim();
    let splitted = trimmedValue.split(" ").filter((part) => part !== "");
    if (splitted.length < 2) {
        return false;
    }
    return true;
};
